.is-modal {
    display: none;

    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow: hidden;
    z-index: 40;

    &.is-active {
        display: flex;
    }

    .modal-background {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }

    .modal-content {
        position: relative;
        padding: 2em;
        background-color: #222;
        color: #ccc;
        border-radius: 0.5em;
        width: 800px;
        max-height: calc(100vh - 40px);
        overflow: visible;
    }

    .modal-close {
        position: fixed;
        top: 20px;
        right: 20px;
        padding: 0.5em;
        border-radius: 100000px;
        color: #aaa;

        &:hover {
            color: #fff;
            cursor: pointer;
        }
    }
}
