.filter-modal {
    input {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    button {
        padding: 0.5rem 1.5rem;
    }
}

.item-row-expanded {
    padding: 0.5rem 2rem;
    background-color: rgba(0, 0, 0, 0.15);
    ul {
        padding-left: 0;
        list-style: none;
        li {
            padding-left: 0.75rem;
            padding-bottom: 0.25rem;
        }
    }

    .price-chart {
        margin-top: 1rem;
        margin-bottom: 1rem;
        height: 100px;
        flex-grow: 1;
        flex-basis: 1;
    }
}
