.is-flex {
    display: flex;
}

.is-flex-grow {
    flex-grow: 1;
}

.is-full-width {
    width: 100%;
}

.is-full-height {
    height: 100%;
}

.is-space-around {
    justify-content: space-around;
}

.is-space-between {
    justify-content: space-around;
}

.is-flex-row {
    display: flex;
    flex-direction: row;
}

.is-flex-column {
    display: flex;
    flex-direction: column;
}

.is-space-between {
    justify-content: space-between;
}

.is-flex-end {
    justify-content: flex-end;
}

.is-right {
    float: right;
}

.is-left {
    float: left;
}

.is-align-center {
    align-items: center;
}

.is-align-start {
    align-items: flex-start;
}

.label {
    font-size: small;
    font-weight: bold;
    padding: 0 0.5em;
}

.item-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    padding: 0 2rem 0 2rem;

    .icon {
        align-self: center;
    }

    .item-name {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin: 0 0.25em;
    }

    .item-members {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin: 0 0.25em;
    }

    .item-id {
        font-size: 0.75rem;
        line-height: 1.5rem;
        align-self: flex-end;
    }
}

.item-data {
    padding: 0 2rem 0 2rem;
    box-sizing: border-box;
    * {
        margin-bottom: 0.5rem;
    }
}

.indented {
    margin-left: 1rem;
}

.recharts-default-tooltip {
    background-color: #222 !important;
}

.price-chart-sidebar {
    min-width: 250px;
    border-left: 1px solid #ccc;
    padding: 1em 0 1em 3em;
    box-sizing: border-box;

    div {
        padding: 0.15rem 0;
    }

    .category {
        font-size: 1.25em;
        font-weight: bold;
    }
}

.item-summary {
    & > div {
        display: border-box;
        width: 25%;
        max-width: 250px;
        padding: 1rem;
    }
}

// Hack to allow for proper responsive resizing of charts
.chart-container-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 250px;
}

.chart-container-responsive {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0.5em;
    right: 0.5em;
}

.is-text-right {
    text-align: right;
}

.chart-timestep-select {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem;
}
