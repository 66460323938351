.header {
    width: 100%;
    background-color: #333;
    border-bottom: 1px solid #000;
    box-shadow: 0 0 5px 5px #000;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    .home-icon {
        margin: 1rem 0.5rem 1rem 1.5rem;
        display: flex;
        align-items: center;
        padding: 4px;
    }

    .home-logo {
        margin: 1rem 1rem 1rem 0;
        line-height: 1.25rem;
        font-size: 1.25rem;
        font-weight: bold;
        padding-top: 5px;
    }

    .navbar {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        .navbar-item {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;

            text-decoration: none;
            font-size: 1.1rem;
            padding: 1rem;
            box-sizing: border-box;

            &:hover {
                background-color: #444;
            }
        }
    }
}
