@import-normalize;

@import '../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.8rem;
    background-color: #222;
    color: #cccccc;
}

a {
    color: #eecc80;
    text-decoration: none;
    padding: 0.25rem;

    &:hover {
        text-decoration: underline;
    }
}

span.positive {
    color: green;
}

span.negative {
    color: red;
}

table.data-grid {
    width: 100%;
    font-size: small;

    border-collapse: collapse;
    border-spacing: 0;

    td,
    th {
        padding: 0.5em;
    }

    td {
        border-top: 1px solid #cccccc;
    }

    tbody tr:hover,
    th:hover {
        background-color: #f5f5f5;
    }

    thead {
        th {
            text-align: left;
            border-bottom: 2px solid #cccccc;
            cursor: pointer;
        }
    }
}

label {
    padding: 0 0.5rem;
    font-weight: bold;
}

input {
    border: 1px solid #111111;
    background-color: #333333;
    color: #cccccc;
    margin: 0.25rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-sizing: border-box;
    box-shadow: inset 0 0.0625rem 0.125rem rgba(10, 10, 10, 0.05);

    &:active,
    &:focus {
        outline: none;
        border-color: #3273dc;
        box-shadow: 0 0 0 0.125rem rgba(50, 115, 220, 0.25);
    }

    &::placeholder {
        color: #cccccc;
    }

    &:invalid {
        border-color: red;
        box-shadow: 0 0 0 0.125rem rgba(220, 50, 10, 0.25);
    }
}

.button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .label {
        align-self: center;
    }

    button,
    .button {
        &:first-child:not(:only-child) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            margin-left: 0.25rem;
        }

        &:last-child:not(:only-child) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            margin-right: 0.25rem;
        }

        &:not(:last-child):not(:only-child) {
            margin-right: -1px;

            &:hover {
                z-index: 2;
            }
        }

        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 0.5rem 0.75rem;
    }
}

button,
.button {
    background-color: #333333;
    border: 1px solid #111111;
    color: #cccccc;
    margin: 0.25rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-sizing: border-box;
    cursor: pointer;

    .icon {
        margin: 0 0.125rem;
    }

    &:not(.is-disabled):not(:disabled):hover {
        outline: none;
        border-color: #aaaaaa;
    }

    &.is-primary {
        background-color: #1d72aa;
    }

    &.is-dangerous {
        background-color: #aa1d1d;
    }

    &.is-active {
        color: #1d72aa;
        border-color: #3273dc;
        z-index: 2;
    }

    &.is-disabled,
    &:disabled {
        cursor: default;
        color: #7a7a7a;
    }
}

.container {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}

.title {
    font-size: 1.5rem;
    margin: 0 0 0.75rem 0;
    line-height: 1.25rem;
}

.subtitle {
    font-size: 1.25rem;
    margin: 0 0 0.75rem 0;
    font-weight: 400;
}

.positive {
    color: #ff0000;
}

.negative {
    color: #00ff00;
}

.members-cell {
    text-align: center;
}

.gold {
    color: #ddaa00;
}